import React from 'react';

class Applications extends React.Component {
  componentDidMount() {
    window.location.replace('https://panel.studiofnc.pl/');
  }
  render() {
    return null;
  }
}

export default Applications;
